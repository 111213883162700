<template lang="pug">
.content
  .vertical-time-line
    .step
      .circle-holder
        .number 1
      .d-flex.container
        .title {{ $t('integrationFlow.theMarketer.copyCustomerAndRestID') }}
        .description
          a(href="https://app.themarketer.com/settings/technical-integration" target="_blank") {{ $t('integrationFlow.theMarketer.linkToIDs') }}
    .step
      .circle-holder
        .number 2
      .d-flex.container
        .title {{ $t('integrationFlow.theMarketer.pasteIDs') }}
        .description
          .input-holder
            om-input#customerID.w-100(
              v-model.trim="settings.restId"
              type="text"
              :label="$t('integrationFlow.theMarketer.restId')"
            )
              template(#error v-if="validations.restId.$error && !validations.restId.required")
                span {{ $t('integrationFlow.theMarketer.restIdError') }}
          .input-holder
            om-input#customerID.w-100(
              v-model.trim="settings.customerId"
              type="text"
              :label="$t('integrationFlow.theMarketer.customerId')"
            )
              template(#error v-if="validations.customerId.$error && !validations.customerId.required")
                span {{ $t('integrationFlow.theMarketer.customerIdError') }}
    .step
      .circle-holder
        .number 3
      integration-name(:name.sync="settings.name" :validations="validations")
</template>
<script>
  import IntegrationName from '@/components/IntegrationModals/IntegrationName';

  export default {
    components: {
      IntegrationName,
    },

    props: {
      settings: {
        type: Object,
        required: true,
      },

      validations: {
        type: Object,
        required: true,
      },
    },
  };
</script>
